/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

.App {
  font-family: 'Playfair Display', serif; /* Unified font */
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #121212; /* Dark background */
  color: #e0e0e0; /* Light gray text */
  text-align: center;
}

.logo {
  font-size: 2.5rem;
  font-weight: 700;
  color: #50c878; /* Emerald green */
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.subtitle {
  font-size: 1rem;
  font-weight: 400;
  color: #a1a1a1;
  margin-top: 0.5em;
}

.scroll-down {
  margin-top: 2em;
}

.scroll-down a {
  text-decoration: none;
  color: #50c878;
  font-weight: bold;
  font-size: 1.2rem;
  border: 2px solid #50c878;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.scroll-down a:hover {
  background-color: #50c878;
  color: #121212;
}

.about {
  height: 100vh;
  background-color: #1e1e1e; /* Slightly lighter dark */
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1em;
}

.about h2 {
  font-size: 2rem;
  color: #50c878;
  margin-bottom: 1rem;
}

.about p {
  font-size: 1.2rem;
  max-width: 600px;
  line-height: 1.6;
  margin-bottom: 1em;
}

.contact {
  font-size: 0.9rem;
  font-weight: 400; /* Normal weight */
  color: #50c878;
}

.contact a {
  text-decoration: none;
  color: #50c878; /* Matches the theme */
}

.contact a:hover {
  text-decoration: underline;
}
